/******************************/
// Variables
/******************************/
$colorGrayDark: #555;

$colorValid: #fffbe6;
$colorEvenValid: #faf7de;
$colorOddValid: #faf7de;

$colorHeader: none;
$colorHeaderFont: $colorGrayDark;

$colorCategory: #80a3ae;
$colorSubCategory: #ebf2f5;

$colorEven: #fdfdfd;
$colorOdd: #fdfdfd;

$colorGreen: #4cce5c;
$colorEvenGreen: #b6fda5;
$colorOddGreen: #b1f89f;

$colorTag:#278eb1;

$colorActive: #9d9fa01f;

$colorRed: #d83679;
$colorEvenRed: #f6e2e5;
$colorOddRed:  #f6e2e5;

$colorHover: #ffffff;

$borderColor: transparent;
$spacing: 6px;
$cellHeight: 40px;

$fontSize: 16px;

$inputFocusBorderColor: rgba(0, 0, 0, 0.4);
$primaryColor: #278eb1;

/******************************/
// Mixins
/******************************/
@mixin setThirdLevelBackground($zero_cell) {
	.editable-cell-zero {
		background-color: $zero_cell;
	}
}

/******************************/
// Body
/******************************/
body {
	min-width: 1200px;
}

/******************************/
// Tabulator
/******************************/
body.sidesheet-open{
	.tabulator-row.tabulator-selected {
		background-color: $colorActive;
	}
}

.tabulator {
	background: none;
	border-radius: 2px;
	border: 1px solid $borderColor;
	margin-bottom: 60px;

	.tabulator-header {
		background: none;

		.tabulator-col-content {
			padding: $spacing !important;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}

		.tabulator-arrow {
			position: relative !important;
			top: auto !important;
			right: auto !important;
		}
	
		.tabulator-col {
			background: $colorHeader;
			border: none;
			color: $colorHeaderFont;
			height: $cellHeight !important;
			display: inline-flex;
			align-items: center;
		}
		
		.tabulator-col-title {
			font-size: $fontSize;
			font-weight: normal;
		}
	}
	.center-column-children {
		justify-content: center;
	}

	/******************************/
	// Row
	/******************************/
	.tabulator-row {
		border-bottom:1px solid #ececec;
		&.tabulator-row-even {
			background-color: $colorEven;
		}
		&.tabulator-row-odd {
			background-color: $colorOdd;
		}
		&:hover {
			background: $colorHover;
			cursor: auto;
		}

		&.row-line-item {
			[tabulator-field='label'] {
				cursor: pointer;
			}

			[tabulator-field='lin'] {
				cursor: default;
			}
			.MuiIconButton-root{
				padding: 5px
			}

		}

		&.row-line-qd-item{
			[tabulator-field='label'] {
				cursor: default;
			}

			[tabulator-field='lin'] {
				cursor: default;
			}	

			.MuiIconButton-root{
				padding: 5px;
				cursor: default;
			}
		}
		
		&.row-qd-parent{
			[tabulator-field='lin']{
				cursor: default;
				svg {
					display: none;	
				}
			}

			[tabulator-field='type'] {
				 .line-item-type-indicator {
					cursor: default;
				}
			}

			.MuiIconButton-root{
				padding: 5px;
				cursor: default;
			}
		}

		[tabulator-field='lin'] {
			cursor: default;
		}
		&:not(.level-1-row) [tabulator-field='lin'] {
			padding-left: 25px;
		}

		.tabulator-data-tree-control {
			display: none;
		}
		.tabulator-data-tree-branch {
			display: none;
		}
	}

	.level-1-row {
		background-color: $colorCategory !important;
		color: #f8f8f8;
	}

	.level-2-row {
		background-color: $colorSubCategory !important;
	}

	.fulfillment-item {
		[tabulator-field='label'] {
			cursor: pointer;
		}
	}

	.tabulator-row{
		.tabulator-cell{
		  &.tabulator-editing{
			padding: 6px !important;
			outline-width: 0;
			  &:focus {
				outline: none;
				outline-width: 0;
				}
			  input{
				padding: 0 !important;
				font-family: inherit !important;
				&:focus {
				  outline: none;
				}
			  }
			  select{
				  &:focus {
					  outline: none;
					}
			  }
			  button{
				  &:focus {
					  outline: none;
					}
			}
		  }    
		}
	  }

	/******************************/
	// Growable Row
	/******************************/
	.tabulator-row.row-growable {
		.tabulator-cell {
			height: auto !important;
		}

		.formatterCell {
			display: inline-flex;
			flex-wrap: wrap;
		}
	}

	/******************************/
	// Cell Tags
	/******************************/
	.tabulator-cell.pointer {
		cursor: pointer;
	}

	.tabulator-cell.cell-tags {
		.MuiChip-root {
			background: $colorTag;
			min-width: 0;
			display: inline-flex;
			padding: 0 10px;
			margin: 2px;
		}
	}

	/******************************/
	// Cell
	/******************************/
	.tabulator-cell {
		border-right: 1px solid $borderColor;
		padding: $spacing;
		font-size: $fontSize;
		height: $cellHeight !important;
		display: inline-flex;
		align-items: center;

		& > input {
			font-size: $fontSize;
			padding: $spacing;
		}

		.chip-wrapper {
			display: flex;
			justify-content: space-between;

			&.chip-empty {
				justify-content: flex-end;
			}
		}

		.lin-wrapper {
			display: flex;
			flex: 1;
			justify-content: space-between;
		}
		
		.zz {
			color: #bbbbbb;
			display: flex;
			flex: 1;
			justify-content:flex-end;
		}

		.MuiChip-root {
			color: white;
			font-weight: bold;
			min-width: 52px;
			margin: -2px;

			&.chip-positive {
				background: $colorGreen;
			}

			&.chip-negative {
				background: $colorRed;
			}

			.MuiChip-label {
				padding: 0;
			}
		}
	}
}

.tabulator-row-odd {
	.table-cell-valid {
		background: $colorEvenValid;
	}

	.table-cell-invalid {
		background: $colorEvenRed;
	}

	.lowest-price-cell {
		background-color: $colorEvenGreen;
	}

	.suggestion-label-cell {
		padding-left: 35px;
	}
}

.tabulator-row-even {
	.table-cell-valid {
		background: $colorOddValid;
	}

	.table-cell-invalid {
		background: $colorOddRed;
	}

	.lowest-price-cell {
		background-color: $colorOddGreen;
	}

	.suggestion-label-cell {
		padding-left: 35px;
	}
}


.declined-column {
	opacity: 0.5;
}

.up,
.up > input {
	display: inline-block !important;
	line-height: #{$fontSize * 2};
	vertical-align: middle;
}

.line-item-type-indicator {
	background: $colorValid;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	border: 1px solid $colorGrayDark;
}

/******************************/
// Stripe
/******************************/

.StripeElement {
	padding-bottom: 5px;
	border-bottom: 1px solid $inputFocusBorderColor;
}

.StripeElement--focus {
	outline: 0;
	border-bottom: 1px solid $primaryColor;
}
